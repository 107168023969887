import PropTypes from 'prop-types';
import '/assets/styles/partials/pricebox.scss';

const PriceBox = ({ product, oldPrice, price, big, alignRight, strike, showCurrency, ...props }) => {
	if (product) {
		if (props.showTotal && product.total_price) {
			if (oldPrice !== false && product.total_price_before_campaigns && product.total_price_before_campaigns.original > product.total_price.original) {
				oldPrice = product.total_price_before_campaigns.original_str;
			}

			price = product.total_price.original_str;
		}
		else {
			if (oldPrice !== false && product.old_price && product.old_price.original > 0 && product.old_price.original > product.price.original) {
				oldPrice = product.old_price.original_str;
			}
			else {
				oldPrice = false;
			}

			price = product.price.original_str;
		}
	}
	else if(typeof price === 'number') {
		price = price.toFixed(2).replace('.', ',');
	}

	return price ? (
		<div className={'pricebox ' + props.className + (big ? ' big' : '') + (alignRight ? ' align-right' : '') + (strike ? ' strike' : '')}>
			<span className="pricebox-top">
				{(product && alignRight && product.discount_ratio !== undefined && product.discount_ratio !== null && product.discount_ratio !== 0) &&
					<span className={'label pink top-discount' + (big ? '' : ' small')}>%{product.discount_ratio}{big ? ' indirim' : ''}</span>
				}
				<span className="top-price">{price}</span>
				{showCurrency &&
					<span className="top-currency">TL</span>
				}
				{(product && !alignRight && product.discount_ratio !== undefined && product.discount_ratio !== null && product.discount_ratio !== 0) &&
					<span className={'label pink top-discount' + (big ? '' : ' small')}>%{product.discount_ratio}{big ? ' indirim' : ''}</span>
				}
			</span>
			{oldPrice &&
				<span className="pricebox-old">{oldPrice} TL</span>
			}
		</div>
	) : false
}

PriceBox.defaultProps = {
	className: '',
	oldPrice: null,
	price: false,
	showTotal: false,
	alignRight: false,
	product: false,
	showCurrency: true,
	strike: false,
}

PriceBox.propTypes = {
	price: PropTypes.oneOfType([PropTypes.object, PropTypes.number, PropTypes.string, PropTypes.oneOf([false])]),
	product: PropTypes.oneOfType([PropTypes.oneOf([false]), PropTypes.object]),
	oldPrice: PropTypes.string,
	className: PropTypes.string,
	showTotal: PropTypes.bool,
	showCurrency: PropTypes.bool,
	strike: PropTypes.bool,
	alignRight: PropTypes.bool,
	big: PropTypes.bool,
}

export default PriceBox;